import React, { useState, useEffect } from 'react';
import { httpClient } from '../axios';
import { useParams } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';
import { CKEditor } from 'ckeditor4-react';
import { useRef } from 'react';
import Popup from 'reactjs-popup';
import styled from 'styled-components';
import { useMemo } from 'react';

const StyledPopupEdit = styled(Popup)`
  // use your custom style for ".popup-overlay"
  /* &-overlay {
 ...;
} */
  // use your custom style for ".popup-content"
  &-content {
    background-color: #ffffff;
    color: #333333;
    border-radius: 32px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 16px;
    padding-right: 16px;
    width: fit-content;
    max-height: 90vh; // Set a maximum height (80% of the viewport height)
    overflow-y: auto; // Enable vertical scrolling when content overflows
  }
  &-overlay {
    background: rgba(0, 0, 0, 0.1);
  }
`;

const ViewQuestions = () => {
  const [questionList, setQuestionList] = useState([]);
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [questionToDelete, setQuestionToDelete] = useState(null);
  const navigate = useNavigate();

  let { userId } = useParams();
  let { colorCode } = useParams();

  const [filtertext, setFiltertext] = useState('');

  const [status, setStatus] = useState('Accepted');

  const GetActiveQuestions = async () => {
    try {
      const res = await httpClient.get(
        `api/question/getByUserId/${userId}/${status}`
      );
      setQuestionList(res.data);
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    GetActiveQuestions();
  }, [status]);

  const config = {
    // allowedContent: true,
    extraPlugins: 'image2,mathjax',
    // removeButtons: 'Paste,PasteText,PasteFromWord',
    // mathJaxLib:
    //   'https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.9/MathJax.js?config=TeX-MML-AM_CHTML',
    versionCheck: false,
    height: '400px',
    // Image settings
    image2_alignClasses: [
      'image-align-left',
      'image-align-center',
      'image-align-right',
    ], // optional, for alignment

    // image2_captionedClass: 'image-captioned',
    // removePlugins: 'sourcearea',
    // toolbar: [
    //   {
    //     name: 'clipboard',
    //     items: [
    //       'Cut',
    //       'Copy',
    //       'Paste',
    //       'PasteText',
    //       'PasteFromWord',
    //       '-',
    //       'Undo',
    //       'Redo',
    //     ],
    //   },
    //   {
    //     name: 'insert',
    //     items: ['Image', 'Table', 'HorizontalRule', 'SpecialChar'],
    //   },
    //   { name: 'styles', items: ['Styles', 'Format'] },
    //   {
    //     name: 'basicstyles',
    //     items: ['Bold', 'Italic', 'Strike', '-', 'RemoveFormat'],
    //   },
    //   {
    //     name: 'paragraph',
    //     items: [
    //       'NumberedList',
    //       'BulletedList',
    //       '-',
    //       'Outdent',
    //       'Indent',
    //       '-',
    //       'Blockquote',
    //     ],
    //   },
    //   { name: 'links', items: ['Link', 'Unlink', 'Anchor'] },
    //   { name: 'tools', items: ['Maximize'] },
    // ],
  };

  const postImageApi = async (blobs, data, type) => {
    // Use Promise.all to wait for all file upload promises to resolve
    const uploadedUrls = await Promise.all(
      blobs.map(async (blob) => {
        let formData = new FormData();
        formData.append('file', blob);

        try {
          const res = await httpClient.post(
            '/api/v1/upload/file',
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }
          );

          console.log('File uploaded successfully');
          console.log(res);

          // Return the uploaded file URL from the response
          return res.data.url;
        } catch (err) {
          console.error('File upload failed', err);
          throw err; // Optional: You could handle failed uploads differently
        }
      })
    );
    console.log('uploadedUrls', uploadedUrls);
    console.log('data', data);

    let finalData = replaceImageSrc(uploadedUrls, data);

    // setarrayWithLinks(uploadedUrls);

    console.log('finalData', finalData);
    // console.log('finalData', finalData);

    // Add style for images to prevent stretching
    // data = data.replace(/<img>/g, '<img height: "19">');

    // Trim whitespace for cleaner inline formatting (optional)
    // finalData = finalData.replace(/\s+/g, ' ').trim();

    if (type === 'question') {
      setEditObjTemp((prev) => ({
        ...prev,
        question: finalData,
      }));
    } else if (type === 'option1') {
      setEditObjTemp((prev) => ({
        ...prev,
        option1: finalData,
      }));
    } else if (type === 'option2') {
      setEditObjTemp((prev) => ({
        ...prev,
        option2: finalData,
      }));
    } else if (type === 'option3') {
      setEditObjTemp((prev) => ({
        ...prev,
        option3: finalData,
      }));
    } else if (type === 'option4') {
      setEditObjTemp((prev) => ({
        ...prev,
        option4: finalData,
      }));
    } else if (type === 'explanation') {
      setEditObjTemp((prev) => ({
        ...prev,
        explanation: finalData,
      }));
    } else if (type === 'solution') {
      setEditObjTemp((prev) => ({
        ...prev,
        solution: finalData,
      }));
    }

    // setData(finalData);
    // Return an array of URLs after all uploads have finished
    // return uploadedUrls;
  };

  function replaceImageSrc(uploadedUrls, data) {
    // Create a regular expression to match the img tags with src attributes
    const imgRegex = /<img[^>]+src="[^"]*"[^>]*>/g;

    // Find all img tags in the data string
    let imgTags = data.match(imgRegex);

    // Loop through each img tag and replace the src with the corresponding URL from uploadedUrls
    if (imgTags && uploadedUrls.length === imgTags.length) {
      imgTags.forEach((imgTag, index) => {
        const newImgTag = imgTag.replace(
          /src="[^"]*"/,
          `src="${uploadedUrls[index]}"`
        );
        data = data.replace(imgTag, newImgTag);
      });
    } else {
      console.warn(
        'The number of uploaded URLs and image tags do not match.'
      );
    }

    return data;
  }

  const editorRef = useRef(null);
  const editorRef1 = useRef(null);
  const editorRef2 = useRef(null);
  const editorRef3 = useRef(null);
  const editorRef4 = useRef(null);
  const editorRef5 = useRef(null);

  const handleDelete = async () => {
    try {
      const res = await httpClient.delete(
        `api/question/delete/${questionToDelete}`
      );
      GetActiveQuestions();
      setOpen(false);
    } catch (error) {
      console.log('Error deleting question:', error);
    }
  };

  const handleClickOpen = (questionId) => {
    setQuestionToDelete(questionId);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setQuestionToDelete(null);
  };

  const [editObj, setEditObj] = useState();
  const [editObjTemp, setEditObjTemp] = useState();
  // Navigate to AddQuestion page with the current question object
  const handleEdit = (obj) => {
    console.log(obj);
    setEditObj(obj); // Update the object to edit
    setEditObjTemp(obj); // Update the object to edit

    setOpenEdit(true); // Open the popup
  };

  useEffect(() => {
    if (openEdit && editorRef.current && editObj?.question) {
      // Set the editor content dynamically
      editorRef.current.setData(editObj.question);
    }
  }, [editObj?.question, openEdit]); // Re-run when `editObj.question` or `openEdit` changes

  useEffect(() => {
    GetActiveQuestions();
  }, []);

  useEffect(() => {
    handleDelete();
  }, []);

  const handleFilterChange = (e) => {
    setFiltertext(e.target.value);
  };

  const filteredQuestions = questionList.filter((question) =>
    question.question.toLowerCase().includes(filtertext.toLowerCase())
  );

  // Function to convert base64 to Blob
  function base64ToBlob(base64Data, contentType) {
    try {
      // Ensure the data is in the expected Base64 format
      if (!base64Data.includes(',')) {
        throw new Error('Invalid Base64 format: Missing data prefix');
      }
      const base64String = base64Data.split(',')[1]; // Extract the Base64 portion
      const byteCharacters = atob(base64String); // Decode Base64 string
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      return new Blob([byteArray], { type: contentType });
    } catch (error) {
      console.error(
        'Error converting Base64 to Blob:',
        error.message
      );
      return null; // Return null if conversion fails
    }
  }

  // Function to convert an array of base64 strings to an array of Blobs
  function convertBase64ArrayToBlobs(base64Array, contentType) {
    return base64Array
      .map((base64String) => {
        const blob = base64ToBlob(base64String, contentType);
        if (!blob) {
          console.warn(
            'Invalid Base64 string skipped:',
            base64String
          );
        }
        return blob;
      })
      .filter((blob) => blob !== null); // Exclude invalid Blobs
  }

  const extractImageSrc = (htmlString) => {
    // Create a temporary div element to parse the HTML string
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlString;

    // Get all <img> elements from the parsed HTML
    const imgElements = tempDiv.getElementsByTagName('img');

    // Extract the src attributes into an array
    const imgSrcArray = Array.from(imgElements).map((img) => img.src);

    return imgSrcArray;
  };

  // Memoize editObj to avoid unnecessary renders
  const stableEditObj = useMemo(() => editObj, [editObj]);

  const [loader, setloader] = useState(false);

  const EditFunc = async () => {
    setloader(true);
    try {
      const res = await httpClient.put(
        `/api/question/update`,
        editObjTemp
      );
      GetActiveQuestions();
      console.log(res.data);
      // setQuestionList((prevList) =>
      //   prevList.filter((question) => question.questionId !== questionToDelete)
      // );
      setOpenEdit(false);
    } catch (error) {
      console.log('Error updating question:', error);
    } finally {
      setloader(false);
    }
  };

  const statusList = ['Accepted', 'Rejected', 'pending'];

  return (
    <>
      <p
        className='text-[28px]
       text-[#757575]
       font-thin text-center
        py-[16px]'
      >
        View{' '}
        <span className='text-[#4E54C8] font-light underline'>
          Questions
        </span>
      </p>

      {/* filter fild */}
      <div className='px-[10px] py-[10px] flex gap-[20px] justify-end fixed top-0 right-0'>
        <div>
          <select
            className='form-select h-[46px] w-[100%] max-w-[300px] mt-[10px]'
            aria-label='Default select example'
            value={status || ''}
            onChange={(e) => {
              const selectedStatus = e.target.value;

              const selectedstatus = statusList?.find(
                (exam) => exam === selectedStatus
              );

              if (selectedstatus) {
                setStatus(selectedstatus);
              }
            }}
          >
            <option value=''>Select Type of Question</option>

            {statusList?.map((obj, index) => (
              <option key={index} value={obj}>
                {obj}
              </option>
            ))}
          </select>
        </div>

        <div>
          <input
            type='text'
            placeholder='Filter questions...'
            value={filtertext}
            onChange={handleFilterChange}
            className='border border-gray-300 p-[10px] rounded-md w-[100%] mr-48 mt-[10px]'
          />
        </div>
      </div>

      {filteredQuestions?.length > 0 ? (
        <div className='px-[10px]'>
          {filteredQuestions?.map((obj) => (
            <>
              <div
                className='flex flex-col 
             border border-[#EFEFEF]
              text-[14px] py-[25px]'
                style={{
                  display: 'flex ',
                  width: '100%',
                }}
                key={obj.questionId}
              >
                <div
                  className='flex items-center  
             gap-[25px]
             p-[15px]'
                  style={{ overflowX: 'auto' }}
                >
                  <div style={{ fontSize: '14px' }}>
                    {obj.questionId}
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '15px',
                    }}
                  >
                    <div className='max-w-[1070px] w-[100%] '>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: obj.question,
                        }}
                      ></p>
                    </div>
                    <div
                      style={{
                        maxWidth: '850px',
                        width: '100%',
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '28px',
                      }}
                    >
                      {[
                        'option1',
                        'option2',
                        'option3',
                        'option4',
                      ].map((option, index) => (
                        <div
                          key={index}
                          className={`p-[8px] flex-wrap max-w-[400px] w-[100%]`}
                          style={{
                            // width: '400px',
                            border: '1.5px solid',
                            borderColor: obj.multiAnswers?.includes(
                              option
                            )
                              ? '#22C55E'
                              : '#EFEFEF',
                            // display: 'flex',
                            // justifyContent: 'center',
                            // alignItems: 'center',
                          }}
                          dangerouslySetInnerHTML={{
                            __html: obj[option],
                          }}
                        />
                      ))}
                    </div>
                    <p>Explanation:</p>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: obj.explanation,
                      }}
                    ></p>

                    <div>
                      <span>
                        Exam: {obj?.entranceExamName} (
                        {obj?.yearOfAppearance})
                      </span>{' '}
                      <br />
                      <span>Subject: {obj?.subjectName}</span>
                    </div>
                  </div>

                  <div
                    style={{
                      flex: 1,
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginRight: '15px',
                    }}
                  >
                    <div></div>

                    <div style={{ display: 'flex' }}>
                      <div
                        style={{
                          height: '30px',
                          width: '35px',
                          border: '2px solid #EFEFEF',
                          borderRadius: '10px 0 0 10px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          handleEdit(obj);
                        }} // Navigate to AddQuestion page
                      >
                        <i className='fa-solid fa-pen-to-square'></i>
                      </div>
                      <div
                        style={{
                          height: '30px',
                          width: '35px',
                          border: '2px solid #EFEFEF',
                          borderRadius: '0px 10px 10px 0px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                        onClick={() =>
                          handleClickOpen(obj.questionId)
                        } // Open the delete confirmation popup
                      >
                        <i className='fa-solid fa-trash'></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <StyledPopupEdit
                open={openEdit}
                closeOnDocumentClick={false}
                position={'top center'}
                onClose={() => {
                  setOpenEdit(false);
                  document.body.classList.remove('body-no-scroll');
                  // setOpenOnMountPop2(true);
                }}
              >
                <div
                  className='relative'
                  style={{
                    backgroundColor: 'white',
                    padding: '20px',
                    borderRadius: '10px',
                    width: '700px',
                    textAlign: 'center',
                  }}
                >
                  <i
                    onClick={() => setOpenEdit(false)}
                    className='fa-solid fa-xmark absolute top-[0px] right-[0px] cursor-pointer'
                  ></i>

                  <div className='flex justify-between text-[21px] mt-[15px]'>
                    <span className='text-[#757575]'>
                      Question Id. {editObj?.questionId}
                    </span>
                    <span className='text-[#757575]'>
                      Exam: {editObj?.entranceExamName}
                    </span>
                  </div>
                  <span className='text-[#757575] text-[21px] flex justify-start my-[15px]'>
                    Subject: {editObj?.subjectName}
                  </span>

                  <p className='mt-[30px] text-[#757575] flex flex-start'>
                    Question
                  </p>
                  <CKEditor
                    config={{
                      ...config,
                      width: '650px',
                      height: '250px', // Customize height
                    }}
                    onInstanceReady={(event) => {
                      editorRef.current = event.editor; // Save CKEditor instance
                      if (editObj?.question) {
                        event.editor.setData(editObj.question);
                      }
                    }}
                    onChange={(event) => {
                      let dataa = event.editor.getData();

                      let data = dataa;
                      console.log('data', data);

                      // Replace <ol> and <li> tags with <span> tags for inline behavior
                      data = data
                        .replace(/<\/?ol>/g, '') // Remove <ol> and </ol> tags
                        .replace(/<\/li>/g, '</span>') // Replace </li> with </span>
                        .replace(
                          /<li>/g,
                          `<span style="display: flex; flex-wrap: wrap; white-space: nowrap;">`
                        )
                        .replace(
                          /<p>/g,
                          `<p style="display: flex; flex-wrap: wrap; white-space: nowrap;">`
                        );

                      const imageSources = extractImageSrc(data);

                      // Convert base64 array to Blob array
                      const blobs = convertBase64ArrayToBlobs(
                        imageSources,
                        'image/png'
                      );

                      // Call API to upload images
                      const res = postImageApi(
                        blobs,
                        data,
                        'question'
                      );
                    }}
                  />

                  <div className='flex flex-col gap-[20px]'>
                    <div className='flex gap-[15px] mx-[auto] mt-[20px]'>
                      <div className='flex flex-col'>
                        <p className='mt-[30px] text-[#757575] flex flex-start'>
                          Option 1
                        </p>
                        <CKEditor
                          config={{
                            ...config,
                            width: '250px',
                            height: '200px', // Customize height
                          }}
                          onInstanceReady={(event) => {
                            editorRef1.current = event.editor; // Save CKEditor instance
                            if (editObj?.option1) {
                              event.editor.setData(editObj.option1); // Set initial content
                            }
                          }}
                          onChange={(event) => {
                            let dataa = event.editor.getData();

                            let data = dataa;
                            console.log('data', data);

                            // Replace <ol> and <li> tags with <span> tags for inline behavior
                            data = data
                              .replace(/<\/?ol>/g, '') // Remove <ol> and </ol> tags
                              .replace(/<\/li>/g, '</span>') // Replace </li> with </span>
                              .replace(
                                /<li>/g,
                                `<span style="display: flex; flex-wrap: wrap; white-space: nowrap;">`
                              )
                              .replace(
                                /<p>/g,
                                `<p style="display: flex; flex-wrap: wrap; white-space: nowrap;">`
                              );

                            const imageSources =
                              extractImageSrc(data);

                            // Convert base64 array to Blob array
                            const blobs = convertBase64ArrayToBlobs(
                              imageSources,
                              'image/png'
                            );

                            // Call API to upload images
                            const res = postImageApi(
                              blobs,
                              data,
                              'option1'
                            );
                          }}
                        />
                      </div>

                      <div className='flex flex-col'>
                        <p className='mt-[30px] text-[#757575] flex flex-start'>
                          Option 2
                        </p>
                        <CKEditor
                          config={{
                            ...config,
                            width: '250px',
                            height: '200px', // Customize height
                          }}
                          onInstanceReady={(event) => {
                            editorRef2.current = event.editor; // Save CKEditor instance
                            if (editObj?.option2) {
                              event.editor.setData(editObj.option2); // Set initial content
                            }
                          }}
                          onChange={(event) => {
                            let dataa = event.editor.getData();

                            let data = dataa;
                            console.log('data', data);

                            // Replace <ol> and <li> tags with <span> tags for inline behavior
                            data = data
                              .replace(/<\/?ol>/g, '') // Remove <ol> and </ol> tags
                              .replace(/<\/li>/g, '</span>') // Replace </li> with </span>
                              .replace(
                                /<li>/g,
                                `<span style="display: flex; flex-wrap: wrap; white-space: nowrap;">`
                              )
                              .replace(
                                /<p>/g,
                                `<p style="display: flex; flex-wrap: wrap; white-space: nowrap;">`
                              );

                            const imageSources =
                              extractImageSrc(data);

                            // Convert base64 array to Blob array
                            const blobs = convertBase64ArrayToBlobs(
                              imageSources,
                              'image/png'
                            );

                            // Call API to upload images
                            const res = postImageApi(
                              blobs,
                              data,
                              'option2'
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div className='flex gap-[15px] mx-[auto] mt-[20px]'>
                      <div className='flex flex-col'>
                        <p className='mt-[30px] text-[#757575] flex flex-start'>
                          Option 3
                        </p>
                        <CKEditor
                          config={{
                            ...config,
                            width: '250px',
                            height: '200px', // Customize height
                          }}
                          onInstanceReady={(event) => {
                            editorRef3.current = event.editor; // Save CKEditor instance
                            if (editObj?.option3) {
                              event.editor.setData(editObj.option3); // Set initial content
                            }
                          }}
                          onChange={(event) => {
                            let dataa = event.editor.getData();

                            let data = dataa;
                            console.log('data', data);

                            // Replace <ol> and <li> tags with <span> tags for inline behavior
                            data = data
                              .replace(/<\/?ol>/g, '') // Remove <ol> and </ol> tags
                              .replace(/<\/li>/g, '</span>') // Replace </li> with </span>
                              .replace(
                                /<li>/g,
                                `<span style="display: flex; flex-wrap: wrap; white-space: nowrap;">`
                              )
                              .replace(
                                /<p>/g,
                                `<p style="display: flex; flex-wrap: wrap; white-space: nowrap;">`
                              );

                            const imageSources =
                              extractImageSrc(data);

                            // Convert base64 array to Blob array
                            const blobs = convertBase64ArrayToBlobs(
                              imageSources,
                              'image/png'
                            );

                            // Call API to upload images
                            const res = postImageApi(
                              blobs,
                              data,
                              'option3'
                            );
                          }}
                        />
                      </div>

                      <div className='flex flex-col'>
                        <p className='mt-[30px] text-[#757575] flex flex-start'>
                          Option 4
                        </p>
                        <CKEditor
                          config={{
                            ...config,
                            width: '250px',
                            height: '200px', // Customize height
                          }}
                          onInstanceReady={(event) => {
                            editorRef4.current = event.editor; // Save CKEditor instance
                            if (editObj?.option4) {
                              event.editor.setData(editObj.option4); // Set initial content
                            }
                          }}
                          onChange={(event) => {
                            let dataa = event.editor.getData();

                            let data = dataa;
                            console.log('data', data);

                            // Replace <ol> and <li> tags with <span> tags for inline behavior
                            data = data
                              .replace(/<\/?ol>/g, '') // Remove <ol> and </ol> tags
                              .replace(/<\/li>/g, '</span>') // Replace </li> with </span>
                              .replace(
                                /<li>/g,
                                `<span style="display: flex; flex-wrap: wrap; white-space: nowrap;">`
                              )
                              .replace(
                                /<p>/g,
                                `<p style="display: flex; flex-wrap: wrap; white-space: nowrap;">`
                              );

                            const imageSources =
                              extractImageSrc(data);

                            // Convert base64 array to Blob array
                            const blobs = convertBase64ArrayToBlobs(
                              imageSources,
                              'image/png'
                            );

                            // Call API to upload images
                            const res = postImageApi(
                              blobs,
                              data,
                              'option4'
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div className='mx-[auto]'>
                      <div className='flex flex-col'>
                        <p className='mt-[30px] text-[#757575] flex flex-start'>
                          explanation
                        </p>
                        <CKEditor
                          config={{
                            ...config,
                            width: '250px',
                            height: '200px', // Customize height
                          }}
                          onInstanceReady={(event) => {
                            editorRef4.current = event.editor; // Save CKEditor instance
                            if (editObj?.explanation) {
                              event.editor.setData(
                                editObj.explanation
                              ); // Set initial content
                            }
                          }}
                          onChange={(event) => {
                            let dataa = event.editor.getData();

                            let data = dataa;
                            console.log('data', data);

                            // Replace <ol> and <li> tags with <span> tags for inline behavior
                            data = data
                              .replace(/<\/?ol>/g, '') // Remove <ol> and </ol> tags
                              .replace(/<\/li>/g, '</span>') // Replace </li> with </span>
                              .replace(
                                /<li>/g,
                                `<span style="display: flex; flex-wrap: wrap; white-space: nowrap;">`
                              )
                              .replace(
                                /<p>/g,
                                `<p style="display: flex; flex-wrap: wrap; white-space: nowrap;">`
                              );

                            const imageSources =
                              extractImageSrc(data);

                            // Convert base64 array to Blob array
                            const blobs = convertBase64ArrayToBlobs(
                              imageSources,
                              'image/png'
                            );

                            // Call API to upload images
                            const res = postImageApi(
                              blobs,
                              data,
                              'explanation'
                            );
                          }}
                        />
                      </div>
                    </div>
                    <button
                      style={{
                        width: 'fit-content',
                        background:
                          '-webkit-linear-gradient(left, #8F94FB, #4E54C8)',
                      }}
                      onClick={() => {
                        if (!loader) {
                          EditFunc();
                        }
                      }}
                      className={`${
                        loader ? 'disabled' : ''
                      } px-[20px] h-[44px] rounded-[5000px] font-medium flex mt-[32px] mx-[auto] justify-center font-light items-center text-[18px] text-[#ffffff]`}
                    >
                      <i
                        className={`fa ${
                          loader ? 'fa-spin fa-spinner' : ''
                        }`}
                      ></i>
                      {loader ? 'Processing' : 'Save'}
                      {/* Submit */}
                    </button>
                    {/* <button
                    style={{
                      width: 'fit-content',
                      background:
                        '-webkit-linear-gradient(left, #8F94FB, #4E54C8)',
                    }}
                    onClick={EditFunc}
                    className=' px-[20px] h-[44px] rounded-[5000px] mx-[auto] font-medium flex mt-[32px] justify-center font-light items-center text-[18px] text-[#ffffff]'
                  >
                    Save
                  </button> */}
                  </div>
                </div>
              </StyledPopupEdit>

              {/* {openEdit && (
              
            )} */}

              {/* Delete Confirmation Popup */}
              <div>
                {open && (
                  <div
                    style={{
                      position: 'fixed',
                      top: '0',
                      left: '0',
                      width: '100%',
                      height: '100%',
                      // boxShadow:
                      //   'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px',
                      backgroundColor: 'rgba(0,0,0,0.2)',
                      // opacity: '50%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: 'white',
                        padding: '20px',
                        borderRadius: '10px',
                        width: '400px',
                        textAlign: 'center',
                      }}
                    >
                      <h2>Confirm Delete</h2>
                      <p>
                        Are you sure you want to delete this question?
                      </p>
                      <div
                        style={{
                          marginTop: '20px',
                          display: 'flex',
                          justifyContent: 'space-around',
                        }}
                      >
                        <button
                          onClick={handleClose}
                          style={{
                            padding: '10px 20px',
                            backgroundColor: '#EFEFEF',
                            borderRadius: '5px',
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          onClick={() => {
                            handleDelete(obj);
                          }}
                          style={{
                            padding: '10px 20px',
                            backgroundColor: '#F44336',
                            color: 'white',
                            borderRadius: '5px',
                          }}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </>
          ))}
        </div>
      ) : (
        <div>
          <p className='text-[22px] mt-[130px] text-center'>
            No Questions Available for this User.
          </p>
        </div>
      )}

      {/* <div className='w-[400px]'>
        <CKEditor
          config={{
            ...config,
            height: '400px', 
          }}
          onInstanceReady={(event) => {
            editorRef.current = event.editor; 
          }}
        />
      </div> */}
    </>
  );
};
export default ViewQuestions;
